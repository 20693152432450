<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 571.3 72"
		enable-background="new 0 0 571.3 72"
		xml:space="preserve"
	>
		<path
			fill="#010101"
			d="M563.6,0.1L563.6,0.1L169.2,0H9.5H7.8C3.5,0,0.1,3.5,0.1,7.8v1.6v53.1v1.7c0,4.3,3.5,7.8,7.8,7.8h555.7
	c4.2,0,7.7-3.5,7.7-7.7V7.8C571.3,3.6,567.8,0.1,563.6,0.1z M568.9,64.3c0,3-2.4,5.3-5.3,5.3H169.2V2.4h394.4v0.1
	c3,0,5.3,2.4,5.3,5.3V64.3z"
		/>
		<linearGradient
			id="SVGID_3_"
			gradientUnits="userSpaceOnUse"
			x1="-121.6519"
			y1="-2084.073"
			x2="63.7241"
			y2="-2084.073"
			gradientTransform="matrix(1 0 0 -1 393.68 -2048.72)"
		>
			<stop offset="0" style="stop-color: #2f9c70" />
			<stop offset="0.54" style="stop-color: #d3de25" />
			<stop offset="1" style="stop-color: #ee3224" />
		</linearGradient>
		<path
			fill="url(#SVGID_3_)"
			d="M449.9,42.8H279.5c-4.1,0-7.5-3.4-7.5-7.5l0,0c0-4.1,3.4-7.5,7.5-7.5h170.4c4.1,0,7.5,3.4,7.5,7.5
	l0,0C457.4,39.5,454.1,42.8,449.9,42.8z"
		/>
		<g>
			<rect
				x="426.6"
				y="18.9"
				fill="#FFDE00"
				stroke="#010101"
				stroke-width="1.632"
				stroke-miterlimit="10"
				width="32.9"
				height="32.9"
			/>
			<g>
				<path
					fill="#010101"
					d="M453.8,26.1c0-1.1-0.8-1.8-1.7-1.8c-0.9,0-1.5,0.6-1.7,1.3l-2.7,8.8l-2.7-8.4c-0.4-1.2-0.9-1.7-1.9-1.7
			c-0.9,0-1.4,0.5-1.8,1.7l-2.7,8.4l-2.7-8.8c-0.3-0.9-1-1.3-1.8-1.3c-1,0-1.7,0.8-1.7,1.8c0,0.3,0,0.5,0.1,0.7l3.9,11.3
			c0.5,1.2,1.2,1.8,2.2,1.8c1,0,1.5-0.6,1.9-1.7l2.5-7.9l2.5,7.9c0.4,1.2,1,1.7,1.9,1.7c1.1,0,1.7-0.6,2.2-1.8l3.9-11.3
			c0,0,0.1-0.5,0.1-0.7l0,0L453.8,26.1z"
				/>
				<path
					fill="#010101"
					d="M452.8,40.8c0,0-0.6,0-1,0.3c-0.8,0.4-3.6,2.8-8.8,2.8s-8.1-2.4-8.8-2.8c-0.5-0.2-0.6-0.3-1-0.3
			c-0.7,0-1.2,0.5-1.2,1.2c0,0.5,0.3,0.8,0.5,0.9c0.9,0.9,4.3,3.7,10.6,3.7c6.2,0,9.7-2.8,10.6-3.7c0.2-0.2,0.5-0.4,0.5-0.9
			c0-0.6-0.6-1.2-1.2-1.2l0,0H452.8z"
				/>
			</g>
		</g>
		<g enable-background="new    ">
			<path
				fill="#FFFFFF"
				d="M50.5,44.3H38.2V24.1h12.3v3.3h-8.6v5h7.7v3.1h-7.7V41h8.6V44.3z"
			/>
			<path fill="#FFFFFF" d="M58.5,44.3h-3.7V24.1h11.7v3.3h-8v5.1H66v3.2h-7.6V44.3z" />
			<path fill="#FFFFFF" d="M73.9,44.3h-3.7V24.1h11.7v3.3h-8v5.1h7.6v3.2h-7.6V44.3z" />
		</g>
		<g enable-background="new    ">
			<path fill="#FFFFFF" d="M98,44.3H85.7V24.1H98v3.3h-8.6v5h7.7v3.1h-7.7V41H98V44.3z" />
		</g>
		<g enable-background="new    ">
			<path
				fill="#FFFFFF"
				d="M115.4,43.8c-1.1,0.5-2.6,0.8-4.8,0.8c-6.5,0-9.9-3.5-9.9-10.1c0-7,3.4-10.7,10.3-10.7
		c2.1,0,3.6,0.4,4.5,0.7v3.4c-1.3-0.5-3-0.7-4-0.7c-4.7,0-6.8,2.1-6.8,7c0,4.7,2.3,7,6.9,7c1.3,0,2.6-0.2,4-0.9V43.8z"
			/>
		</g>
		<g enable-background="new    ">
			<path fill="#FFFFFF" d="M127.6,44.3h-3.7V27.5h-5.8v-3.4h15.3v3.4h-5.8V44.3z" />
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M194.1,37.6c0.6,0.4,2,0.9,3.6,0.9c1.4,0,2-0.5,2-1.4c0-1-0.6-1.2-2.1-1.7l-0.5-0.2c-2.3-0.7-3.2-2-3.2-4.1
		c0-2.3,1.8-3.7,4.8-3.7c1.7,0,2.8,0.3,3.6,0.6v2.7c-1-0.4-2.3-0.5-3.4-0.5c-1.2,0-1.9,0.4-1.9,1.2c0,0.8,0.7,1.1,2.6,1.7l0.5,0.2
		c2.3,0.8,2.9,2.2,2.9,3.9c0,2.6-1.9,4-5.1,4c-1.7,0-3-0.3-4-0.8V37.6z"
			/>
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M212.8,38.2h-5l-0.9,2.8h-3.1l4.4-13.2h4.2l4.3,13.2h-3.2L212.8,38.2z M208.7,35.5h3.3l-1.6-5.4L208.7,35.5
		z"
			/>
		</g>
		<g enable-background="new    ">
			<path fill="#231F20" d="M222.7,40.9h-3.1V30.6h-3.7v-2.9h10.5v2.9h-3.7V40.9z" />
			<path fill="#231F20" d="M231,40.9h-3.1V27.7h3.1V40.9z" />
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M240.6,40.9h-4.1l-4.1-13.2h3.4l2.9,10.2l2.9-10.2h3.2L240.6,40.9z"
			/>
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M252.8,38.2h-5l-0.9,2.8h-3.1l4.4-13.2h4.2l4.3,13.2h-3.2L252.8,38.2z M248.7,35.5h3.3l-1.6-5.4L248.7,35.5
		z"
			/>
		</g>
		<g enable-background="new    ">
			<path fill="#231F20" d="M480,40.9h-3.1V27.7h3.1V40.9z" />
			<path fill="#231F20" d="M493.7,40.9h-3.4l-5-8.9v8.9h-3V27.7h3.6l4.8,8.6v-8.6h3V40.9z" />
			<path
				fill="#231F20"
				d="M496.1,40.9V27.7h4.8c5.1,0,6.7,2.3,6.7,6.4c0,4.6-2.2,6.9-7,6.9H496.1z M499.2,38.2h1.3
		c2.8,0,3.9-1.3,3.9-4c0-2.7-1-3.8-3.7-3.8h-1.5V38.2z"
			/>
			<path fill="#231F20" d="M512.6,40.9h-3.1V27.7h3.1V40.9z" />
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M524.7,40.6c-0.7,0.3-1.8,0.6-3.4,0.6c-4.5,0-6.8-2.4-6.8-6.7c0-4.5,2.2-7,7-7c1.5,0,2.6,0.3,3.2,0.5v2.9
		c-0.8-0.3-2-0.5-2.7-0.5c-2.8,0-4.2,1.1-4.2,4c0,2.7,1.3,4,4.2,4c0.9,0,1.8-0.2,2.7-0.6V40.6z"
			/>
		</g>
		<g enable-background="new    ">
			<path
				fill="#231F20"
				d="M534.8,38.2h-5l-0.9,2.8h-3.1l4.4-13.2h4.2l4.3,13.2h-3.2L534.8,38.2z M530.6,35.5h3.3l-1.6-5.4L530.6,35.5
		z"
			/>
		</g>
	</svg>
</template>
